// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.sravul.np.isccloud.io';
const STAGE = 'sravul';

export const environment = {
  production: true, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_A6yLLbY05',
  NG_APP_COGNITO_CLIENT_ID: '10i8pumn363l0e5kid1rbdmeao',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  SQLAAS_URL: '#sqlaas_url#',
};
